import React, { Suspense, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { NavBar, TopBar, Modals } from './components';
import { UploadingList } from '../../modules/files/components/UploadingList';
import { FileUploaderDropzone } from '../../modules/files/components/FileUploaderDropzone';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import { useDirsActions } from '../../modules/dirs/hooks';
import { useFilesActions } from '../../modules/files/hooks';

const navbarWidth = '250px';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {},
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 64
  },
  navBar: {
    zIndex: 3,
    width: navbarWidth,
    minWidth: navbarWidth,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(100vw - ${navbarWidth})`,
      marginLeft: navbarWidth
    }
  }
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const { fetchDirs } = useDirsActions();
  const { fetchFiles } = useFilesActions();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    fetchDirs();
    fetchFiles();
    // eslint-disable-next-line
  }, []);

  return (
    <FileUploaderDropzone>
      <GatewayProvider>
        <div className={classes.root}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
          />
          <div className={classes.container}>
            <NavBar
              className={classes.navBar}
              onMobileClose={handleNavBarMobileClose}
              openMobile={openNavBarMobile}
            />
            <main className={classes.content}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
              </Suspense>
              <UploadingList />
            </main>
          </div>
          <Modals />
          <GatewayDest name="footer" />
        </div>
      </GatewayProvider>
    </FileUploaderDropzone>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
