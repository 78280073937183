import {
  createResourceReducer,
  ResourceState
} from '../@common/reducers/resource-reducer';
import { move } from 'utils/array';
import { DIRS_PREFIX } from './constants';
import { handleActions } from 'redux-actions';
import { dirsActions } from './actions';
import { Action } from '../@common/actions/action';
import { Dir } from './index';
import { File } from '../files';
import { filesActions } from '../files/actions';
import produce from 'immer';
import { basketActions } from '../basket/actions';

interface RootDirsState {
  items: string[];
  order: number[];
}

const initialRootsState: RootDirsState = {
  items: [],
  order: []
};

export const rootDirsReducer = handleActions(
  {
    [dirsActions.FETCH.SUCCESS]: (state, action: Action) => {
      const { isRoot, order } = action.payload;
      if (isRoot) {
        return {
          ...state,
          order,
          items: Object.keys(action.payload.items).map(key => +key)
        };
      }

      return state;
    },
    [dirsActions.MOVE.TRIGGER]: (state, action: Action) => {
      const { id, position, parent } = action.payload;
      const prevIndex = state.order.indexOf(id);

      if (parent) {
        return {
          ...state,
          order: state.order.filter((itemId: number) => +itemId !== +id)
        };
      }

      if (prevIndex !== -1) {
        return {
          ...state,
          order: move(state.order, prevIndex, position)
        };
      }

      return state;
    },
    [dirsActions.MOVE.SUCCESS]: (state, action: Action) => {
      const { parent, id } = action.payload;

      if (parent) {
        return {
          ...state,
          items: state.items.filter((itemId: number) => +itemId !== +id)
        };
      } else {
        if (!state.items.includes(id)) {
          return {
            ...state,
            items: [...state.items, id]
          };
        }
      }

      return state;
    }
  },
  initialRootsState
);

const getDirFilesIds = (dir: Dir) => {
  const files = Array.from(dir.files || []);

  return files.map((file: File) => file.id);
};

interface DirsResourceState extends ResourceState<Dir> {
  isDownloading: boolean;
}

const baseDirsReducer = createResourceReducer<Dir>(DIRS_PREFIX);

const initialState: DirsResourceState = {
  items: {},
  order: [],
  isFetching: false,
  isSubmitting: false,
  didCreate: false,
  didUpdate: false,
  pendingItemId: null,
  errors: null,
  isDownloading: false
};

export const dirsReducer: typeof baseDirsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case basketActions.fetch.SUCCESS: {
      const { directories = [] } = action.payload;

      return produce(state, draft => {
        directories.forEach((dir: Dir) => {
          if (!state.items[dir.id]) {
            draft.items[dir.id] = {
              ...dir
            };
          }
        });
      });
    }

    case dirsActions.preload.SUCCESS:
    case dirsActions.fetch.SUCCESS: {
      const { items = {} } = action.payload;
      const { parentId } = action.meta;

      return produce(state, draft => {
        const ids: Dir['id'][] = [];
        Object.keys(items).forEach(id => {
          ids.push(+id as Dir['id']);
          if (!draft.items[id]) {
            draft.items[id] = items[id];
          }
        });

        if (parentId) {
          if (!draft.items[parentId]) {
            // @ts-ignore
            draft.items[parentId] = {
              id: parentId
            };
          }

          draft.items[parentId].childrenIds = ids;
        }
      });
    }

    case dirsActions.READ.SUCCESS: {
      const dir = action.payload as Dir;

      return produce(state, draft => {
        draft.items[dir.id] = {
          ...draft.items[dir.id],
          ...dir,
          filesIds: getDirFilesIds(dir),
          files: [],
          __isLoaded: true
        };
      });
    }

    case dirsActions.CREATE.SUCCESS: {
      const dir = action.payload;
      const parent = dir.parent;

      const nextState = produce(state, draft => {
        draft.items[dir.id] = dir;
        if (parent && parent.id && draft.items[parent.id]) {
          if (draft.items[parent.id].childrenIds) {
            const prevChildren = Array.from(
              draft.items[parent.id].childrenIds || []
            );
            prevChildren.push(dir.id);

            draft.items[parent.id].childrenIds = prevChildren;
          }
        }
      });

      return baseDirsReducer(nextState, action);
    }

    case dirsActions.move.TRIGGER: {
      const { id, parent, position, siblings = {} } = action.payload;

      return produce(state, draft => {
        const dir = draft.items[id];

        if (dir) {
          if (dir.parentId && parent !== dir.parentId) {
            const parentId = dir.parentId;
            const prevParent = state.items[parentId];
            draft.items[parentId].childrenIds = (
              prevParent.childrenIds || []
            ).filter((childId: number) => childId !== id);
            draft.items[parentId].childrenCount = Array.from(
              draft.items[parentId].childrenIds || []
            ).length;
          }

          dir.parentId = parent;
          dir.position = position;

          // update also siblings positions to prevent flickering UI
          Object.keys(siblings).forEach(key => {
            draft.items[key].position = siblings[key];
          });
        }
      });
    }

    case dirsActions.move.SUCCESS: {
      return produce(state, draft => {
        const { id } = action.payload;

        draft.items[id].path = action.payload.path;
      });
    }

    case filesActions.CREATE.SUCCESS: {
      return produce(state, draft => {
        const { directory, id } = action.payload;

        if (directory && directory.id && draft.items[directory.id]) {
          const dir = draft.items[directory.id];
          dir.filesIds = dir.filesIds || [];
          dir.filesIds.push(id);
        }
      });
    }

    case filesActions.move.REQUEST: {
      return produce(state, draft => {
        const { dirId, file } = action.payload;
        const prevDirId = file.directoryId;

        if (dirId && draft.items[dirId]) {
          const dir = draft.items[dirId];
          dir.filesIds = dir.filesIds || [];
          dir.filesIds.push(file.id);
        }

        if (prevDirId && draft.items[prevDirId]) {
          const prevDir = draft.items[prevDirId];
          prevDir.filesIds = (prevDir.filesIds || []).filter(
            fileId => fileId !== file.id
          );
        }
      });
    }

    case dirsActions.download.TRIGGER: {
      return produce(state, draft => {
        // @ts-ignore
        draft.isDownloading = true;
      });
    }

    case dirsActions.download.FULFILL: {
      return produce(state, draft => {
        // @ts-ignore
        draft.isDownloading = false;
      });
    }

    case dirsActions.search.SUCCESS: {
      return produce(state, draft => {
        const { dirs = [] } = action.payload;

        dirs.forEach((dir: Dir) => {
          if (!draft.items[dir.id]) {
            draft.items[dir.id] = dir;
          }
        });
      });
    }
    default:
      return baseDirsReducer(state, action);
  }
};
