import React, { Component } from 'react';
import SortableTree, {
  ExtendedNodeData,
  FullTree,
  NodeData,
  OnMovePreviousAndNextLocation,
  OnVisibilityToggleData,
  TreeIndex,
  TreeNode
} from 'react-sortable-tree';
import theme from './Theme';

interface TreeProps {
  treeData: any[];
  isVirtualized?: boolean;
  onChange: (treeData: any[]) => void;
  onBeforeChildrenRender: ({ node }: any) => any | null;
  onMoveNode?(data: NodeData & FullTree & OnMovePreviousAndNextLocation): void;
  onVisibilityToggle?(data: OnVisibilityToggleData): void;
  getNodeKey?(data: TreeNode & TreeIndex): string | number;
  generateNodeProps?(data: ExtendedNodeData): { [index: string]: any };
  canDrag?: ((data: ExtendedNodeData) => boolean) | boolean;
}

export default class Tree extends Component<TreeProps> {
  static defaultProps = {
    onBeforeChildrenRender: () => null,
    onChange: () => {},
    onMoveNode: () => {},
    onVisibilityToggle: () => {}
  };

  render() {
    const {
      isVirtualized = false,
      treeData,
      getNodeKey,
      onChange,
      canDrag,
      onMoveNode,
      onVisibilityToggle,
      generateNodeProps
    } = this.props;

    return (
      <div style={{ height: isVirtualized ? '50vh' : '100%' }}>
        <SortableTree
          canDrag={canDrag}
          isVirtualized={isVirtualized}
          treeData={treeData}
          onChange={onChange}
          onVisibilityToggle={onVisibilityToggle}
          onMoveNode={onMoveNode}
          getNodeKey={getNodeKey}
          generateNodeProps={generateNodeProps}
          // @ts-ignore
          theme={theme}
        />
      </div>
    );
  }
}
