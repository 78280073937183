import { RootState } from '../../store';
import { SelectUsersParams } from './index';

export function selectUsers(state: RootState, params: SelectUsersParams) {
  const orderIds = (state.users.order || []) as number[];
  const items = orderIds.map((id: number) => state.users.items[id]);

  if (params.search) {
    return items.filter(item =>
      item.email.toLowerCase().includes((params.search || '').toLowerCase())
    );
  }

  return items;
}

export function isFetching(state: RootState) {
  return state.users.isFetching;
}

export function selectUser(state: RootState, id: number) {
  return state.users.items[id];
}

export function isUserFetching(state: RootState, id: number) {
  return state.users.pendingItemId === id;
}
